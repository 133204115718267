import React, { useState, FC, useEffect } from "react";
import { Skeleton, Stack, Switch, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";

import { ChelemDTO, ChelemModel, dtoToModel } from "./mkart.types";
import ChelemListItem from "./chelemListItem";
import axios from "axios";

const ListItems = (props: {
  chelems: ChelemModel[];
  isFullListEnabled: boolean;
}) => {
  return (
    <>
      {props.chelems
        // .filter((c) => props.isFullListEnabled || c.nb === 16)

        .map((chelem) => (
          <Box
            key={chelem._id}
            sx={{
              display:
                props.isFullListEnabled || chelem.nb === 16 ? "block" : "none",
              background: "#ffffff",
              justifyContent: "center",
              textAlign: "center",
              p: "15px",
              borderRadius: "15px",
              alignItems: "center",
              boxShadow:
                "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
            }}
          >
            <Link
              to={`${chelem._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ChelemListItem chelem={chelem} />
            </Link>
            {/* <hr /> */}
          </Box>
        ))}
    </>
  );
};

interface HistProps {}
export const HistChelems: FC<HistProps> = () => {
  const [chelems, setchelems] = useState<ChelemModel[]>([]);
  useEffect(() => {
    console.log("reload from api");
    axios.get<ChelemDTO[]>("/api/chelems").then((r) => {
      const chelemsResult = r.data
        .map((c) => {
          if (c.date) {
            c.date = new Date(c.date);
          } else {
            c.date = new Date();
          }

          return dtoToModel(c);
        })
        // @ts-ignore
        .sort((c1, c2) => c2.date.getTime() - c1.date.getTime());
      setchelems(chelemsResult);
    });
  }, []);

  const [isFullListEnabled, setisFullListEnabled] = useState(false);

  return (
    <Stack
      sx={{
        // background: "#F5F5F5",
        paddingTop: "25px",
        paddingLeft: "25px",
        paddingRight: "25px",
      }}
      direction="column"
      spacing={2}
    >
      <Stack direction={"row"} justifyContent={"right"}>
        <Typography variant="h6" color="#ffffff">
          Voir tout
        </Typography>
        <Switch
          checked={isFullListEnabled}
          onChange={() => setisFullListEnabled(!isFullListEnabled)}
        />
      </Stack>
      {chelems.length ? (
        <ListItems chelems={chelems} isFullListEnabled={isFullListEnabled} />
      ) : (
        <>
          <Skeleton variant="text" animation={"wave"} />
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={60}
            animation={"wave"}
          />
          <Skeleton variant="text" animation={"wave"} />
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={60}
            animation={"wave"}
          />
          <Skeleton variant="text" animation={"wave"} />
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={60}
            animation={"wave"}
          />
        </>
      )}
    </Stack>
  );
};
