export const RACES = [
  { img: "luigicircuit.jpg", name: "luigi" },
  { img: "Peach_Beach.PNG", name: "plage" },
  { img: "baby_park.PNG", name: "baby park" },
  { img: "desert secsec.jpg", name: "desert" },
  { img: "pont champignon.jpg", name: "pont champi" },
  { img: "MarioCircuit.jpg", name: "mario" },
  { img: "paquebot.PNG", name: "paquebot" },
  { img: "stade waluigi.jpg", name: "stade" },
  { img: "royaume sorbet.jpg", name: "glace" },
  { img: "champiville.jpg", name: "champivil" },
  { img: "circuit yoshi.PNG", name: "yoshi" },
  { img: "montagne dk.jpg", name: "montagne" },
  { img: "arene wario.jpg", name: "arene" },
  { img: "jungle dindino.jpg", name: "jungle" },
  { img: "chateau bowser.jpg", name: "château" },
  { img: "route arc en ciel.PNG", name: "arcenciel" },
];

export type RaceResult = {
  j1: number;
  j2: number;
  comment?: string;
  date?: Date;
  stars: number;
};

export type Race = {
  name: string;
  img: string;
  result: RaceResult;
};

export type ChelemModel = {
  _id?: string;
  courses: Race[];
  nb: number;
  ptsJ1: number;
  ptsJ2: number;
  date?: Date;
};

export type RaceDTO = {
  course_id: number;
  j1: number;
  j2: number;
  comment: string;
  stars?: number;
  date: Date | undefined;
};

export type ChelemDTO = {
  _id?: string;
  courses: RaceDTO[];
  nb: number;
  ptsJ1: number;
  ptsJ2: number;
  date: Date;
};

export const sortChelemRaces = (r1: Race, r2: Race): number => {
  if (r1.result.date && r2.result.date) {
    return r1.result.date.getTime() - r2.result.date.getTime();
  }
  if (r2.result.date) {
    return 1;
  }
  if (r1.result.date) {
    return -1;
  }

  return (
    RACES.findIndex((r) => r.name === r1.name) -
    RACES.findIndex((r) => r.name === r2.name)
  );
};

export const dtoToModel = (chelemDto: ChelemDTO): ChelemModel => {
  const chelem: ChelemModel = {
    ...chelemDto,
    date: chelemDto.date ? new Date(chelemDto.date) : chelemDto.date,
    courses: chelemDto.courses
      .map((c) => ({
        name: RACES[c.course_id - 1].name,
        img: RACES[c.course_id - 1].img,
        result: {
          j1: c.j1,
          j2: c.j2,
          comment: c.comment,
          stars: c.stars || 0,
          date: c.date ? new Date(c.date) : c.date,
        },
      }))
      .sort(sortChelemRaces),
  };
  return chelem;
};

export const modelToDTO = (chelemModel: ChelemModel): ChelemDTO => {
  const chelem: ChelemDTO = {
    ...chelemModel,
    date: chelemModel.date || new Date(),
    courses: chelemModel.courses.map((c) => ({
      course_id: RACES.findIndex((r) => r.name === c.name) + 1,
      j1: c.result.j1,
      j2: c.result.j2,
      stars: c.result.stars,
      comment: c.result.comment || "",
      date: c.result.date,
    })),
  };
  return chelem;
};
