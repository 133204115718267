import React, { useEffect, useState, FC } from "react";
import { Backdrop, Chip, Stack, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TourIcon from "@mui/icons-material/Tour";
import StarIcon from "@mui/icons-material/Star";

import { Box } from "@mui/system";
import {
  Race,
  RaceResult,
  ChelemModel,
  ChelemDTO,
  dtoToModel,
  modelToDTO,
  sortChelemRaces,
  RACES,
} from "./mkart.types";
import { useParams, useNavigate } from "react-router-dom";

import { Course } from "./course";
import axios from "axios";

interface ChelemProps {
  new?: boolean;
}

const calcPoints = (r: RaceResult, joueur: "j1" | "j2"): number => {
  const points = [0, 10, 8, 6, 4, 3, 2, 1, 0];
  return points[r[joueur]];
};

export const Chelem: FC<ChelemProps> = ({ new: isNew }: ChelemProps) => {
  let navigate = useNavigate();
  const initialChelem: ChelemModel = {
    nb: 0,
    ptsJ1: 0,
    ptsJ2: 0,
    courses: RACES.map((r) => ({ ...r, result: { j1: 0, j2: 0, stars: 0 } })),
  };
  const [chelem, setChelem] = useState<ChelemModel>(initialChelem);

  const [open, setOpen] = useState(false);
  const [isBackdropOpen, setBackdropOpen] = useState(false);

  let { chelemId } = useParams<"chelemId">();

  useEffect(() => {
    if (chelemId) {
      console.log("load chelem " + chelemId);
      axios.get<ChelemDTO>("/api/chelems/" + chelemId).then((r) => {
        const chelemResult: ChelemModel = dtoToModel(r.data);
        setChelem(chelemResult);
      });
    } else if (isNew) {
      setChelem(initialChelem);
    }
  }, [chelemId, isNew]);

  const handleChange = async (course: Race) => {
    const newCourses = [
      ...chelem.courses.filter((c) => c.name !== course.name),
      course,
    ];
    const stat = newCourses.reduce(
      (acc, c) => {
        acc.ptsJ1 += calcPoints(c.result, "j1");
        acc.ptsJ2 += calcPoints(c.result, "j2");
        if (c.result.j1 && c.result.j2) {
          acc.nb++;
        }
        return acc;
      },
      { ptsJ1: 0, ptsJ2: 0, nb: 0 }
    );
    const newChelem = {
      ...chelem,
      courses: newCourses.sort(sortChelemRaces),
      ...stat,
    };
    if (chelem?._id) {
      await axios.patch("/api/chelems/" + chelem._id, modelToDTO(newChelem));
      setChelem(newChelem);
      setOpen(true);
    } else {
      const result = await axios.post("/api/chelems", modelToDTO(newChelem));
      newChelem._id = result.data._id;
      navigate(`/${newChelem._id}`);
    }
  };

  const handleClose = (event: any, reason: any) => {
    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => handleClose(null, null)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const isBong = [0, 5, 10, 15].includes(chelem.nb);
  useEffect(() => {
    if (isBong) {
      setBackdropOpen(true);
    }
  }, [isBong, chelem.nb]);
  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          alignContent="center"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            sx={{
              background: "#ffffff",
              p: "10px",
              m: "5px",
              borderRadius: "15px",
              alignItems: "center",
              width: "85px",
              height: "65px",
              display: "flex",
            }}
            variant="h6"
          >
            <TourIcon /> {chelem.nb} / 16
          </Typography>
          <Box
            sx={{
              background: "#ffffff",
              justifyContent: "center",
              textAlign: "center",
              p: "10px",
              m: "5px",
              borderRadius: "15px",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">En cours:</Typography>
            <Typography>
              {chelem.ptsJ1} - {chelem.ptsJ2}
            </Typography>
            <Typography>
              {chelem.nb
                ? Math.round(
                    ((chelem.ptsJ1 + chelem.ptsJ2) / (chelem.nb * 2)) * 100
                  ) / 100
                : 0}
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#ffffff",
              justifyContent: "center",
              textAlign: "center",
              p: "10px",
              m: "5px",
              borderRadius: "15px",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Max:</Typography>
            <Typography>
              {chelem.ptsJ1 + (16 - chelem.nb) * 9} -{" "}
              {chelem.ptsJ2 + (16 - chelem.nb) * 9}
            </Typography>
            <Typography>
              {Math.round(
                ((chelem.ptsJ1 + chelem.ptsJ2 + (16 - chelem.nb) * 18) /
                  (16 * 2)) *
                  100
              ) / 100}
            </Typography>
          </Box>
        </Stack>
        {chelem.courses.map((course) => (
          <Course
            key={course.name}
            course={course}
            handleChange={handleChange}
          />
        ))}
      </Stack>
      <Snackbar
        open={open}
        autoHideDuration={600}
        onClose={handleClose}
        message="Enregistrement OK"
        action={action}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isBackdropOpen}
        onClick={() => {
          setBackdropOpen(false);
        }}
      >
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          Hit from the Bong !!
        </Typography>
      </Backdrop>
    </>
  );
};
