import React, { useEffect, useState, FC } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Race, RaceResult } from "./mkart.types";
import { Star, StarBorderOutlined } from "@mui/icons-material";

interface CourseProps {
  course: Race;
  handleChange: (r: Race) => void;
}

export const Course: FC<CourseProps> = ({ course, handleChange }) => {
  const [result, setResult] = useState<RaceResult>({ j1: 0, j2: 0, stars: 0 });
  const [hasChange, setChange] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleSave = () => {
    setEditMode(false);
    if (result.j1 === 0 || result.j2 === 0) {
      return;
    }
    course.result = result;
    if (!course.result.date) {
      course.result.date = new Date();
    }
    console.log("save course", { course });
    handleChange(course);
  };
  useEffect(() => {
    if (course.result) {
      setResult(course.result);
    }
  }, [course.result]);

  const handlePos = (joueur: "j1" | "j2", pos: number) => {
    if (!pos) pos = 0;
    if (result[joueur] === pos) {
      return;
    }

    setChange(true);
    const newResult = { ...result };
    newResult[joueur] = pos;
    setResult(newResult);
  };
  const listPos: Array<"j1" | "j2"> = ["j1", "j2"];

  const formatDate = (d: Date | undefined) => {
    if (!d) {
      return "";
    }
    return d.toLocaleTimeString("fr");
  };

  return (
    <div>
      {!editMode ? (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              ml: "25px",
              mr: "25px",
              background: "#ffffff",
              borderRadius: "15px",
              boxShadow:
                "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
            }}
          >
            <Box sx={{ position: "absolute", top: "0px", right: "30px" }}>
              {[...Array(course.result.stars)].map((i) => (
                <Star htmlColor="rgb(255, 237, 34)" />
              ))}
            </Box>
            <Box
              //   className="main-element"
              onClick={() => {
                setEditMode(true);
              }}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                p: "15px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "60px", height: "60px", borderRadius: "50%" }}
                alt=""
                src={`/img/${course.img}`}
              />
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {course.name}
              </Typography>
              <Box>
                {result.j1 === 1 && result.j2 === 1 ? (
                  <>
                    <button className="subscribe-button">{result.j1}</button>
                    <button className="subscribe-button">{result.j2}</button>
                  </>
                ) : (
                  listPos.map((pos) => (
                    <Button
                      key={pos}
                      size="small"
                      sx={{
                        borderRadius: "50%",
                        width: 35,
                        height: 35,
                        minWidth: 30,
                      }}
                      variant={result[pos] > 0 ? "contained" : "outlined"}
                      color={
                        [1, 2].includes(result[pos])
                          ? "success"
                          : [3, 4, 5, 6].includes(result[pos])
                          ? "warning"
                          : [7, 8].includes(result[pos])
                          ? "error"
                          : "info"
                      }
                    >
                      {result[pos]}
                    </Button>
                  ))
                )}
              </Box>
              {/* <Box sx={{ width: 30 }}>{calcPoints(result) / 2}</Box> */}
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.46) 0px 0px 4px 0px inset",
              mt: "20px",
              mb: "20px",
              pb: "20px",
              background: "#ffffff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "& > *": {
                m: 1,
              },
            }}
          >
            <h3>{course.name}</h3>
            <span>{formatDate(result?.date)}</span>
            {listPos.map((pos) => (
              <Box key={pos} sx={{ display: "inline-flex" }}>
                <ToggleButtonGroup
                  color="success"
                  sx={{ mr: 2 }}
                  size="medium"
                  value={result[pos]}
                  exclusive
                  onChange={(e, v) => handlePos(pos, v)}
                >
                  {[1, 2].map((i) => (
                    <ToggleButton value={i}>{i}</ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <ToggleButtonGroup
                  color="warning"
                  sx={{ mr: 2 }}
                  size="medium"
                  value={result[pos]}
                  exclusive
                  onChange={(e, v) => handlePos(pos, v)}
                >
                  {[3, 4, 5, 6].map((i) => (
                    <ToggleButton value={i}>{i}</ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <ToggleButtonGroup
                  color="error"
                  value={result[pos]}
                  exclusive
                  onChange={(e, v) => handlePos(pos, v)}
                  size="medium"
                  aria-label="large button group"
                >
                  {[7, 8].map((i) => (
                    <ToggleButton value={i}>{i}</ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>
            ))}
            <Box>
              <IconButton
                onClick={() => {
                  setResult({ ...result, stars: result.stars - 1 });
                }}
              >
                <StarBorderOutlined />
              </IconButton>
              {result.stars || 0}
              <IconButton
                onClick={() => {
                  setResult({ ...result, stars: result.stars + 1 });
                }}
              >
                <Star />
              </IconButton>
            </Box>
            <Button
              onClick={handleSave}
              variant="contained"
              color={hasChange ? "warning" : "info"}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};
