import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";

import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import "./App.scss";
import ListIcon from "@mui/icons-material/List";

import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import { Chelem } from "./components/chelem";
import { HistChelems } from "./components/hist";

function App() {
  const [value, setValue] = useState("hist");
  const handleNav = (event: any, newValue: string) => {
    setValue(newValue);
  };

  function Layout() {
    return (
      <div>
        {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
        <Box
          paddingBottom="56px"
          sx={{ width: 1, paddingBottom: "56px", background: "#1849A3" }}
        >
          <Outlet />
        </Box>
        <BottomNavigation
          sx={{
            background: "#E33027",
            color: "#ffffff",
            position: "fixed",
            bottom: 0,
            width: 1.0,
            zIndex: 200,
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            borderRadius: "4px",
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
          }}
          value={value}
          onChange={handleNav}
        >
          <Link to="/">
            <BottomNavigationAction
              value="hist"
              icon={<ListIcon sx={{ color: "#ffffff" }} />}
            />
          </Link>
          <Link to="new">
            <BottomNavigationAction
              value="newChelem"
              icon={<AddCircleIcon sx={{ color: "#ffffff" }} />}
            />
          </Link>
        </BottomNavigation>
      </div>
    );
  }
  return (
    <BrowserRouter>
      {/* <Route path="/products/:id" component={Product} /> */}

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HistChelems />} />
          <Route path="new" element={<Chelem new={true} />} />
          <Route path=":chelemId" element={<Chelem />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
