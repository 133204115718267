import React, { FC, memo } from "react";
import { Chip, Stack } from "@mui/material";
import TourIcon from "@mui/icons-material/Tour";
import StarIcon from "@mui/icons-material/Star";
import PeopleIcon from "@mui/icons-material/People";
import { Box } from "@mui/system";
import { ChelemModel } from "./mkart.types";

interface ChelemListItemProps {
  chelem: ChelemModel;
}
const ChelemListItem: FC<ChelemListItemProps> = ({ chelem }) => {
  const formatDate = (d: Date | undefined) => {
    var options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    if (!d) {
      return "";
    }
    return d.toLocaleDateString("fr", options);
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} justifyContent="space-between" mb={2}>
        <Chip
          icon={<TourIcon />}
          sx={{ fontSize: ".8rem", background: "#417BCB" }}
          size="medium"
          label={`${chelem.nb}/16`}
          color={chelem.nb === 16 ? "success" : "default"}
        />
        <Chip
          icon={
            <PeopleIcon
              htmlColor="#5AAE3C"
              color="inherit"
              sx={{ color: "#5AAE3C" }}
            />
          }
          sx={{
            fontSize: ".8rem",
            fontWeight: "bold",
            color: "#5AAE3C",
            border: "0px",
          }}
          variant="outlined"
          label={`${chelem.ptsJ1} - ${chelem.ptsJ2}`}
        />
        <Chip
          icon={<StarIcon />}
          sx={{
            fontSize: ".8rem",
            background: chelem.nb === 16 ? "#F1CF47" : "#F1CF47",
          }}
          label={
            chelem.nb > 0
              ? Math.round(
                  (((chelem.ptsJ1 + chelem.ptsJ2) / 2) * 1000) / chelem.nb
                ) / 1000
              : 0
          }
        />
      </Stack>
      <Box textAlign="left" sx={{ fontSize: "0.87rem" }}>
        {formatDate(chelem.date)}
      </Box>
    </Box>
  );
};

export default memo(ChelemListItem);
